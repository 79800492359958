
import React, { useState, useEffect, useReducer } from "react";
import { useHistory,useLocation,  Link } from "react-router-dom";
import loader from "../../assets/img/loader.gif";
import "react-datepicker/dist/react-datepicker.css";
import { Header } from '../common/header';
import { Footer } from '../common/footer';
import Cases from '../../data';

export const CaseStudiesList = () => {
  const histroy = useHistory();
  const { pathname } = useLocation();
  const [isLoader, setIsLoader] = useState(false)


  useEffect(() => {
    console.log('Cases', Cases);
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
}, [pathname]);

  const goTo = (item) => {
    histroy.push(`/${item.link}`, item);
  }


  return (
    <div className="main_wrapper">
      <div className="main_inner">
        <Header />
        <div className="main_banner case_wrapper">
          <div className="left_main about_us">
            <h2>The Ease2Uni Case Studies </h2>
            <p>Discover students like you who have been accepted to their dream schools</p>
            <div className="main_cases">
              {Cases && Cases.map((item, index) => {
                return (
                  <div key={index} className="common_case">
                    <img src={item.img} />
                    <h3>{item.title}</h3>
                    <h3 className="highlight">ADMIT: {item.uni}</h3>
                    <span className="button"><Link to={{
                      pathname: `/case-studies/${item.link}`,
                      state: item
                    }}>Read More...</Link></span>
                  </div>
                )
              })}

            </div>
          </div>
        </div>


        <Footer />

        {isLoader && <div className="loader">
          <img src={loader} />
        </div>}
      </div>
    </div>
  )
};
