
import React, { useState, useEffect, useReducer } from "react";
import { useParams } from 'react-router';
import { useHistory, useLocation } from "react-router-dom";
import loader from "../../assets/img/loader.gif";
import Profile from "../../assets/img/aboutus.jpg";
import { axiosInstance as axios } from "../../utils/utils";
import { Header } from '../common/header';
import { Footer } from '../common/footer';
import moment from 'moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import toastr, { success } from "toastr";
toastr.options.preventDuplicates = true;
export const AdminContactUs = () => {
    const histroy = useHistory();
    const { pathname } = useLocation();
    const [isLoader, setIsLoader] = useState(false)
    const [users, setUsers] = useState(null);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        setIsLoader(true)
        axios.get('/user/getAllcontact').then(res => {
            setIsLoader(false);
            setUsers(res?.data?.data);
        }).catch(err => {
            setIsLoader(false)
            toastr.error(err)
        })
    }, [])


    const action = (item) => {
        setIsLoader(true)
        item.status = 'contacted'
        axios.post('/user/updateContact', item).then(res => {
            setIsLoader(false);
            toastr.success("Updated Successfully!!")
            window.location.reload();
        }).catch(err => {
            setIsLoader(false)
            toastr.error(err)
        })

        // event.preventDefault();
        // let keys = Object.keys(state);
        // keys.forEach(element => {
        //     if (state[element] == '') {
        //         return toastr.error(`Please enter ${element}`)
        //     }
        // });
        // setIsLoader(true)
        // axios.post('/user/contact', state).then(res => {
        //     setIsLoader(false)
        //     setThankYou(true)
        //     setTimeout(function () {
        //         setState({
        //             name: "",
        //             email: "",
        //             mobile: "",
        //             message: ""
        //         })
        //         setThankYou(false)
        //     }, 4000)
        // }).catch(err => {
        //     setIsLoader(false)
        //     toastr.error(err)
        // })
    };

    return (
        <div className="main_wrapper">
            <div className="main_inner">
                <Tabs>
                    <TabList>
                        <Tab>Active Enquiries </Tab>
                        <Tab>Contacted Enquiries</Tab>
                    </TabList>

                    <TabPanel>
                        <div className="main_table_box">
                            <table className="userTable">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Mobile No.</th>
                                        <th>Email</th>
                                        <th>Message</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users && users.map((item, index) => {
                                        if (item.status == 'not-contacted')
                                            return (
                                                <tr key={index}>
                                                    <td>{item.name}</td>
                                                    <td>{item.mobile}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.message}</td>
                                                    <td className="actions"><span onClick={() => action(item)}>Mark as Contacted </span></td>
                                                </tr>
                                            )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="main_table_box">
                            <table className="userTable">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Mobile No.</th>
                                        <th>Email</th>
                                        <th>Message</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users && users.map((item, index) => {
                                        if (item.status == 'contacted')
                                            return (
                                                <tr key={index}>
                                                    <td>{item.name}</td>
                                                    <td>{item.mobile}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.message}</td>
                                                    <td className="actions"><span>Contacted </span></td>
                                                </tr>
                                            )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </TabPanel>
                </Tabs>

                {isLoader && <div className="loader">
                    <img src={loader} />
                </div>}
            </div>
        </div>
    )
};
