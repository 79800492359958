
import React, { useState, useEffect, useReducer } from "react";
import { useHistory, NavLink } from "react-router-dom";
import Logo from "../../assets/img/logo.png";

export const Footer = () => {
    const histroy = useHistory();

    useEffect(() => {

    }, [])

    const paymentPage = () => {
        histroy.push('/payments');
    }
    const seatsPage = () => {
        histroy.push('/bookedseats');
    }

    return (
        <div className="footer_main">
            <p>Grade 12 is the application year to universities, your profile-building starts much earlier.</p>
            <p><NavLink to='/contact-us' className="contact">Contact Us</NavLink> for a free profile assessment and an initial career counselling session.</p>
        </div>
    )
};
