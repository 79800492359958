
import React, { useState, useEffect, useReducer } from "react";
import { useParams } from 'react-router';
import { useHistory, useLocation } from "react-router-dom";
import loader from "../../assets/img/loader.gif";
import Profile from "../../assets/img/aboutus.jpg";
import { axiosInstance as axios } from "../../utils/utils";
import { Header } from '../common/header';
import { Footer } from '../common/footer';
import toastr, { success } from "toastr";
toastr.options.preventDuplicates = true;
export const ContactUs = () => {
  const histroy = useHistory();
  const { pathname } = useLocation();
  const [isLoader, setIsLoader] = useState(false)
  const [thankyou, setThankYou] = useState(false)
  const [state, setState] = useState({
    name: "",
    email: "",
    mobile: "",
    message: ""
  });


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let keys = Object.keys(state);
    keys.forEach(element => {
      if (state[element] == '') {
        return toastr.error(`Please enter ${element}`)
      }
    });

    
    setIsLoader(true)
    axios.post('/user/contact', state).then(res => {
      setIsLoader(false)
      setThankYou(true)
      setTimeout(function () {
        setState({
          name: "",
          email: "",
          mobile: "",
          message: ""
        })
        setThankYou(false)
      }, 4000)
    }).catch(err => {
      setIsLoader(false)
      toastr.error(err)
    })
  };

  return (
    <div className="main_wrapper">
      <div className="main_inner">
        <Header />
        <div className="main_banner">
          <div className="left_main contact_us">
            <h2>Contact Us </h2>
            <div className="left_contact">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3504.3057713216244!2d77.2759583!3d28.5605795!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3e326f29d29%3A0xda00946785c3f8db!2sTHE%20ARDEE%20SCHOOL%2C%20New%20Friends%20Colony!5e0!3m2!1sen!2sin!4v1687775258765!5m2!1sen!2sin"
                width="100%" height="450" loading="lazy" >
              </iframe>
            </div>

            <div className="right_contact">
              {thankyou ? 
              <div className="thankyou">
                <h3>Thank You!</h3>
                <h5>Team will contact you shortly.</h5>
              </div> :
                <form onSubmit={handleSubmit}>
                  <div className="form-control">
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      value={state.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-control">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      value={state.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-control">
                    <label>Phone No.</label>
                    <input
                      type="number"
                      name="mobile"
                      value={state.mobile}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-control">
                    <label>Message</label>
                    <textarea
                      name="message"
                      value={state.message}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-control submit_btn">
                    <button className="submit_btn" type="submit">Submit</button>
                  </div>
                </form>
              }
            </div>

          </div>
        </div>


        <Footer />

        {isLoader && <div className="loader">
          <img src={loader} />
        </div>}
      </div>
    </div>
  )
};
