
import React, { useState, useEffect, useReducer } from "react";
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import loader from "../../assets/img/loader.gif";
import Profile from "../../assets/img/aboutus.jpg";
import "react-datepicker/dist/react-datepicker.css";
import { Header } from '../common/header';
import { Footer } from '../common/footer';
import Project from "../../assets/img/projects.png";

export const Projects = () => {
  const histroy = useHistory();
  const [isLoader, setIsLoader] = useState(false)
  useEffect(() => {

  }, [])

  const paymentPage = () => {
    histroy.push('/payments');
  }

  return (
    <div className="main_wrapper">
      <div className="main_inner">
        <Header />
        <div className="main_banner">
          <div className="left_main about_us">
            <h2>Ease2Uni with Guided Research Projects </h2>
            <ul className="aboutUS">
              <li>Research Fundamentals</li>
              <li>Research Topic Exploration</li>
              <li>Tailored Research Project Schedule Creation and Planning</li>
              <li>Intensive Thesis Development</li>
              <li>Project Scope And Outline Creation</li>
              <li>Research Project Development</li>
              <li>Homework And Assignments</li>
              <li>Creation Of Initial Draft</li>
              <li>Strategic Editing and Proofreading Support</li>
              <li>Creation Of Final Draft</li>
              <li>Certification Of Completion</li>
              <li>Letter Of Recommendation Support</li>
              <li>Expected Program Duration</li>
              <li className="abt_img"><img src={Project} /></li>
            </ul>

          </div>
        </div>


        <Footer />

        {isLoader && <div className="loader">
          <img src={loader} />
        </div>}
      </div>
    </div>
  )
};
