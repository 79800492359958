
import React, { useState, useEffect, useReducer } from "react";
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import { axiosInstance as axios } from "../../utils/utils";
import cookie from 'react-cookies';
import page1 from "../../assets/img/Page1.png";
import page2 from "../../assets/img/Page2.png";
import loader from "../../assets/img/loader.gif";

import PDFViewer from 'pdf-viewer-reactjs'
import "react-datepicker/dist/react-datepicker.css";
import {Header} from '../common/header';
import {Footer} from '../common/footer';
export const Preparation = () => {
  const histroy = useHistory();

  const [isLoader, setIsLoader] = useState(false)

  
  useEffect(() => {

  }, [])


  return (
    <div className="main_wrapper">
      <div className="main_inner">
        <Header />
        <div className="main_banner prep">
  
          <img src={page1} /><img src={page2} />
        </div>


        <Footer />

        {isLoader && <div className="loader">
          <img src={loader} />
        </div>}
      </div>
    </div>
  )
};
