
import React, { useState, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { axiosInstance as axios } from "../../utils/utils";
import cookie from 'react-cookies';
import Logo from "../../assets/img/logo.png";
import loader from "../../assets/img/loader.gif";
import Books from "../../assets/img/home.jpg";
import CA from "../../assets/img/canada.png";
import UK from "../../assets/img/UK.png";
import US from "../../assets/img/US.jpeg";
import EU from "../../assets/img/EU.jpeg";
import Profile from "../../assets/img/profile.jpeg";
import {Header} from '../common/header';
import {Footer} from '../common/footer';
import moment from 'moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "react-datepicker/dist/react-datepicker.css";
import toastr, { success } from "toastr";
toastr.options.preventDuplicates = true;





export const Home = () => {
  const [isLoader, setIsLoader] = useState(false)
  const histroy = useHistory();



  return (
    <div className="main_wrapper">
      <div className="main_inner">
        <Header />
        <div className="main_banner">
          <div className="left_main">
            <h2>The one-on-one, in-depth application support you seek to get the strategic guidance you deserve. </h2>
            <p>The preparation route to your dream university is long and well-strategized; with Ease2Uni it will be simple, focused, and result oriented.</p>
            <span className="c2a"><a href="https://calendly.com/ease2uni/30min" target="_blank">Free Consultation</a> </span>
          </div>
          <div className="right_main">
            <img src={Books} />
          </div>
        </div>
        <div className="vision">
          <div className="common_vis">
            <h2>Vision</h2>
            <h4 className="vis_main">
              Hand-held counseling and guidance with individualized year-wise planning, leading to a holistic profile development of the student leading to our students getting acceptances from their best-fit universities and colleges.</h4>
          </div>
          <div className="common_vis">
            <h2>Our 2022 College Acceptances:</h2>
            <div className="flag_coun">
              <span><img src={US} /></span>
              <h4>Columbia University, University of Chicago, CalTech, UCLA, UC Berkely, Tufts University, Duke University</h4>
            </div>
            <div className="flag_coun">
              <span><img src={UK} /></span>
              
              <h4>King’s College London, Regents University, Warwick, CASS Business School, London College of Fashion, UCA, University College London, University of Manchester, Imperial College London</h4>
            </div>
            <div className="flag_coun">
              <span><img src={EU} /></span>
              <h4>Trinity College Dublin, University College Dublin, TU Delft, University of Amsterdam, Hanze University, Vrije University Amsterdam, TU Munich, Ecole School, Swiss School of Hospitality, University of Burn, NYU </h4>
            </div>
            <div className="flag_coun">
              <span><img src={CA} /></span>
              <h4>University of Waterloo, University of British Columbia, University of Toronto, McGill University, McMaster University, University of Calgary, Wilfrid Laurier University </h4>
            </div>
          </div>
        </div>

        <div className="profile">
          <div className="txt_profile">
            <h2>Kaustubh Kaushik (BPS, EFPA, MSc Psychology)</h2>
            <p>Ease2Uni founder Kaustubh Kaushik is widely recognized as one of the leading experts on career guidance and university admissions in the Delhi NCR region. He is the Head of the University Guidance and Admissions at The Ardee Group of Schools, and with over 8 years of experience in his career, he has helped students gain admission to top-ranked colleges across the globe countless times over.</p>
            <p>Kaustubh earned an MSc in Psychology degree from Middlesex University London and has international counselling and psychometric testing certifications from leading federations and psychological associations. Taking a psychometric approach for analysing students’ interests, strengths, and academic inclinations, he starts with the counselling process and assessments right from Grade 8 students and specializes in documenting their profiles and portfolios that are fit for applications to the IVY league and Russell Group Universities.</p>
            <p>Every year many of Kaustubh’s students receive appreciative feedback on their college essays, portfolios, and research work from the colleges. His hands-on approach to careful profile crafting is the most unique tool in his counsellor’s repertoire. </p>
          </div>
          <div className="img_profile">
            <img src={Profile} />
          </div>
        </div>

        <div className="eccm">
          <div className="main_eccm">
            <h2>Our Empathetic Careers Counselling Methodology <sup>(TM)</sup></h2>
            <p>With a prolonged background in Psychology, the counselor understands the behavioural nuances and interests’ inclinations of teenagers and their parents. Heartfelt understanding that keeps the students in center-focus lays the groundwork for applying the ‘mind-work’ that entails the careful curation of a step-by-step action plan for every student. Individual attention and alliance with a student keep them highly motivated and derive trust in the student and they understand the advice and suggestions. That is the essential ingredient for working and advising students in today’s time.</p>
            <p>Careers counselling is a supporting process, we advise, we teach, and we help you explore, but first, we listen, and we understand YOU for YOU!</p>
          </div>
        </div>

        <Footer />

        {isLoader && <div className="loader">
          <img src={loader} />
        </div>}



      </div>
    </div>
  )
};
