
import React, { useState, useEffect, useReducer } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import loader from "../../assets/img/loader.gif";
import cross from "../../assets/img/cross.png";
import "react-datepicker/dist/react-datepicker.css";
import { Header } from '../common/header';
import { Footer } from '../common/footer';
import Cases from '../../data';

export const Team = () => {
  const histroy = useHistory();
  const { pathname } = useLocation();
  const [isLoader, setIsLoader] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [isSelectedTeamMember, setIsSelectedTeamMember] = useState(false)
  const [Team, setTeam] = useState([{
    name: 'Priti Kaushik',
    image: 'https://ease2uni.com/img/Priti.jpeg',
    acdamic: 'MA, TESOL|IELTS certified',
    desc1: 'Ms. Priti is a certified ECS trainer with over 10 years of experience in training students in developing academic and research-based contemporary English language skills. A proficient academic writer, Ms. Priti possesses exceptional writing skills, including a strong command of the English language, grammar, punctuation, and vocabulary. She is highly skilled in conducting thorough research on various topics. Ms. Priti is proficient in accessing academic databases, libraries, and online resources to gather relevant and reliable information for academic essays and research papers.',
    desc2: 'Ms. Priti is a TESOL Level 5 Language certified trainer in English from the prestigious Trinity College London (CILA) and an A-Level certified German language instructor (Goethe Institute, Munich).',
    desc3: 'With strong professional backgrounds in language training, teaching high schoolers, and research, Ms. Priti brings her prowess in guiding students with their complex ideas, theories, and personal insights, and helps students present a well-reasoned and supported perspective to their research work and academic essays. She also mentors the applicants with their language skills and the much required and essential international testing preparation.'
  },
  {
    name: 'Varun Goel',
    acdamic: 'MBA, University of Oxford',
    image: 'https://ease2uni.com/img/varun.jpeg',
    desc1: 'Varun is the founder of two successful businesses: Shri Ram Agro Innovatives - a sustainable agriculture training agency, as well as KOOK Frozen Foods - a ready-to-eat food processing firm. He recently completed his MBA degree from Saïd Business School (University of Oxford) and was awarded the prestigious fully-funded Weidenfeld-Hoffmann Scholarship.',
    desc2: 'Being an alum of the University of Oxford, Varun has a lot of experience with college application processes and what makes someone a winning candidate. He masters the art of storytelling in college essays and the arduous journey of preparing for top universities in the UK and USA. Being at Oxford served to deepen his understanding of international student life to better assist students transitioning both culturally and academically. He passionately supports students achieve their academic goals across various subjects and age groups. Varun’s competence is to play a pivotal role in guiding students to reach their full potential and take advantage of the benefits of studying and living internationally.',
    desc3: 'Varun’s blog The Beginning of the Journey — Reflections on our Early Weeks in Oxford provides a thoughtful insight into the journey of an international student from preparation for school to living overseas (https://www.whtrust.org/blog/introduction-week2022-t2wyl)'
  },
  {
    name: 'Ankita Arora',
    acdamic: 'MBA | NLP Practitioner | Author | Student Coach',
    image: 'https://ease2uni.com/img/ankita.jpeg',
    desc1: 'Ankita is an HR Professional turned into an entrepreneur, author, and life coach. She has helped countless individuals across various industries discover their true calling and make the necessary steps toward achieving their personal and career goals.',
    desc2: 'She believes one`s personal life and career should be intertwined, so her coaching sessions focus on personal and professional development. She believes that everyone has unique skills and abilities that can be harnessed to achieve their career aspirations.',
    desc3: 'Ankita is the author of the book ’The Smart Balance - For a better living in the digital world which focuses on a healthy, happy, and productive relationship with technology and the internet.',
    desc4: 'Ankita is an alumnus of prestigious educational institutions like Delhi Public School R.K Puram, National Institute of Fashion Technology, and NMIMS Mumbai. She is also a certified NLP Practitioner, Life Coach and has been awarded the Rex KarmaVeer Award.'
  }
  // {
  //   name: 'Tripti Shrivastav',
  //   acdamic: 'Expert Manager',
  //   image: 'https://ease2uni.com/img/ankita.jpeg',
  //   desc1: 'Tripti Shrivastav is an alum of the prestigious National Institute of Design (M. Des. Communication Design) and has over eight years of work experience in digital product innovation in consulting and product firms. She has worked with leading organizations like Bain & Company and Samsung. Tripti’s expertise encompasses digital innovation and thinking, workshop design and facilitation, interaction design, and branding.',
  //   desc2: 'At Ease2Uni Tripti helps the students with their guided ethnographic research and strategy and forays in the space of compassionate inquiry, empathetic narrative, and storytelling while helping students draft their essays and supplementals. Her niche is design and Tripti is hands-on with the students in the preparation of their portfolios and profiles.',
  // }
  ])



  useEffect(() => {
    console.log('Cases', Cases);
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const popOpen = (item) => {
    setSelectedTeamMember(item);
    setIsSelectedTeamMember(true);
  }


  const popClose = () => {
   
    setIsSelectedTeamMember(false);
    setSelectedTeamMember(null);
  }

  


  return (
    <div className="main_wrapper">
      <div className="main_inner">
        <Header />
        <div className="main_banner case_wrapper">
          <div className="left_main about_us">
            <h2>Academic Advisors:</h2>
            <p></p>
            {/* <p>Discover students like you who have been accepted to their dream schools</p> */}
            <div className="main_cases">
              {Team && Team.map((item, index) => {
                return (
                  <div key={index} className="common_case team" onClick={() => popOpen(item)}>
                    <img className="profile_team" src={item.image} />
                    <h3>{item.name}</h3>
                    <h3 className="highlight">{item.acdamic}</h3>
                    <span className="view_profile">View Profile</span>
                  </div>
                )
              })}

            </div>
            {isSelectedTeamMember && <div className="team_pop">
              <div className="inner_pop">
                <img className="cross" onClick={() => popClose()} src={cross} />
                <h2>{selectedTeamMember?.name}</h2>
                <h4>{selectedTeamMember?.acdamic}</h4>
                <p>{selectedTeamMember?.desc1}</p>
                <p>{selectedTeamMember?.desc2}</p>
                <p>{selectedTeamMember?.desc3}</p>
                <p>{selectedTeamMember?.desc4}</p>
              </div>
            </div>
            }
          </div>

        </div>


        <Footer />

        {isLoader && <div className="loader">
          <img src={loader} />
        </div>}
      </div>
    </div>
  )
};
