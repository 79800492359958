
import React, { useState, useEffect, useReducer } from "react";
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import loader from "../../assets/img/loader.gif";

import Profile from "../../assets/img/aboutus.jpg";


import "react-datepicker/dist/react-datepicker.css";
import {Header} from '../common/header';
import {Footer} from '../common/footer';
export const AboutUs = () => {
  const histroy = useHistory();

  const [isLoader, setIsLoader] = useState(false)

  
  useEffect(() => {

  }, [])

  const paymentPage = () => {
    histroy.push('/payments');
  }
  const seatsPage = () => {
    histroy.push('/bookedseats');
  }

  return (
    <div className="main_wrapper">
      <div className="main_inner">
        <Header />
        <div className="main_banner">
          <div className="left_main about_us">
            <h2>Why Ease2Uni? </h2>
            <p>
            Universities are looking at students who gave their nascent interests and hobbies attention and developed them into participation and initiatives.  Ease2Uni works with you in EASY and acceptance-qualifying steps:</p>
           <ul className="aboutUS">
            <li>Identifying academic and co-academic interests and potential pursuits</li>
            <li>Developing a roadmap for profile-building in grades 8-12</li>
            <li>Advice for intra and inter-school competitions</li>
            <li>Psychometric Testing</li>
            <li>Summer schools and international student exchange programs</li>
            <li>Internships</li>
            <li>SAT, AP, and International English Language Testing Coaching</li>
            <li>Research projects, Academic and Art Portfolios</li>
            <li>Personal Statements, SOPs, and Supplemental Essays</li>
            <li>Assistance with Letters of Recommendations</li>
            <li>College Selection and Applications</li>
            <li>Interview preparations</li>
            <li>Scholarships and Financial Aid</li>
            <li className="abt_img"><img src={Profile} /></li>
           </ul>
           
          </div>
        </div>
       

        <Footer />

        {isLoader && <div className="loader">
          <img src={loader} />
        </div>}
      </div>
    </div>
  )
};
