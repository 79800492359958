
import React, { useState, useEffect, useReducer } from "react";
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import loader from "../../assets/img/loader.gif";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "react-datepicker/dist/react-datepicker.css";
import { Header } from '../common/header';
import { Footer } from '../common/footer';
export const Programs = () => {
  const histroy = useHistory();

  const [isLoader, setIsLoader] = useState(false)


  useEffect(() => {

  }, [])

  const paymentPage = () => {
    histroy.push('/payments');
  }
  const seatsPage = () => {
    histroy.push('/bookedseats');
  }

  return (
    <div className="main_wrapper">
      <div className="main_inner">
        <Header />
        <div className="main_banner">
          <div className="left_main about_us">
            <h2>Programs Grades 8-12</h2>
            <p>
              Ease2Uni offers The Most Unique, Peer-Researched, and Destination specific
              University Profile Building Solution <sup>(TM)</sup> that offers you well-structured
              Varsity Admissions with EASE!</p>

            <Tabs>
              <TabList>
                <Tab>Grade 8</Tab>
                <Tab>Grade 9</Tab>
                <Tab>Grade 10</Tab>
                <Tab>Grade 11</Tab>
                <Tab>Grade 12</Tab>
              </TabList>

              <TabPanel>
                <ul className="grades">
                  <li>Academic Development</li>
                  <li>Academic Skill Development</li>
                  <li>Recommendation of reading list</li>
                  <li>Exploration Around Academic Interests Through Supplemental & Online Coursework</li>
                  <li>School Based Leadership Development</li>
                  <li>School Based Activity Participation</li>
                  <li>Guidance around engagement in student clubs, societies and chapters in school</li>
                  <li>Prestigious Student Competition And Conference Participation</li>
                  <li>Preparing for elite academic and non-academic student competitions at regional and national level</li>
                  <li>School Based Leadership Position Planning</li>
                  <li>Extra-Curricular Profile Development</li>
                  <li>Sports Participation And Engagements</li>
                  <li>Development Of Interest In Music</li>
                  <li>Arts/Performing Arts Participation And Engagements</li>
                  <li>Exploration Of Entrepreneurship Projects</li>
                  <li>Portfolio Development</li>
                  <li>Advisement on creation of personal websites, blogs, Udemy courses, Youtube channel, Amazon/Kindle publishing and other related interests</li>
                  <li>Recommendations on virtual and in-person bootcamps and enrichment programs</li>
                  <li>Summer Program Planning & Application Support</li>
                  <li>Internship, shadowing and volunteering opportunities relevant to area of study</li>
                </ul>
              </TabPanel>

              <TabPanel>
                <ul className="grades">
                  <li>Academic Development</li>
                  <li>Academic Advisement & Course Selection</li>
                  <li>Recommendation of reading list</li>
                  <li>Academic Skill Development</li>
                  <li>Exploration Around Academic Interests Through Supplemental & Online Coursework</li>
                  <li>School Based Leadership Development</li>
                  <li>School Based Activity Participation</li>
                  <li>Guidance around engagement in student clubs, societies and chapters in school</li>
                  <li>Prestigious Student Competition And Conference Participation</li>
                  <li>Preparing for elite academic and non-academic student competitions at regional and national level</li>
                  <li>School Based Leadership Position Planning</li>
                  <li>Extra-Curricular Profile Development</li>
                  <li>Sports Participation And Engagements</li>
                  <li>Development Of Interest In Music</li>
                  <li>Arts/Performing Arts Participation And Engagements</li>
                  <li>Exploration Of Entrepreneurship Projects</li>
                  <li>Portfolio Development</li>
                  <li>Advisement on creation of personal websites, blogs, Udemy courses, Youtube channel, Amazon/Kindle publishing and other related interests</li>
                  <li>External Enrichment/Academic Opportunities</li>
                  <li>Recommendations on virtual and in-person bootcamps and enrichment programs</li>
                  <li>Planning For Summer And Other Breaks</li>
                  <li>Summer Program Planning & Application Support</li>
                  <li>Student Internship Planning And Application</li>
                  <li>Student Research Project Planning And Application</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul className="grades">
                  <li>Recommendation of reading list</li>
                  <li>Number Of Applications</li>
                  <li>Early Candidacy Development Hours</li>
                  <li>College Application Support - Edits & Counselor Sessions</li>
                  <li>Personal, Dedicated Admissions Counselor</li>
                  <li>Applicant Persona Development (Narrative Development)</li>
                  <li>Balanced College List Creation (Safety/Target/Reach)</li>
                  <li>Strategic Positioning On Major Selection</li>
                  <li>Identifying Early Action, Early Decision & Regular Decision Schools</li>
                  <li>Common App/UC App/Coalition App/MIT App Support</li>
                  <li>Selecting, Positioning & Editing Activities For Honors List - Edits & Counselor Sessions</li>
                  <li>Strategic Selection Of Essay Prompts</li>
                  <li>Crafting Storyline, Editing, Drafting & Reviewing Personal Statements - Edits & Counselor Sessions</li>
                  <li>Letters Of Recommendation Support</li>
                  <li>Strategic Angular Positioning For Supplemental Essays Based On Each School's Culture And Values</li>
                  <li>Crafting Storyline, Editing, Drafting & Reviewing Supplemental Essays - Edits & Counselor Sessions</li>
                  <li>School-Specific Interview Simulation - Mock Sessions</li>
                  <li>Waitlist And Deferral Support</li>
                  <li>Merit-Based Scholarships - Identification & Application Support - Edits Counselor Sessions & Number Of Scholarships</li>
                  <li>Additional Application Review By A Panel Of 3 Former Admissions Committee Members</li>
                </ul>
              </TabPanel>

              <TabPanel>
                <ul className="grades">
                  <li>Number Of Applications</li>
                  <li>Early Candidacy Development Hours</li>
                  <li>Personal, Dedicated Former Admissions Counselor</li>
                  <li>Applicant Persona Development (Narrative Development)</li>
                  <li>Balanced College List Creation (Safety/Target/Reach)</li>
                  <li>Strategic Positioning On Major Selection</li>
                  <li>Identifying Early Action, Early Decision & Regular Decision Schools</li>
                  <li>Common App/UC App/Coalition App/MIT App Support</li>
                  <li>Selecting, Positioning & Editing Activities For Honors List - Edits & Counselor Sessions</li>
                  <li>Strategic Selection Of Essay Prompts</li>
                  <li>Crafting Storyline, Editing, Drafting & Reviewing Personal Statements - Edits & Counselor Sessions</li>
                  <li>Letters Of Recommendation Support</li>
                  <li>Strategic Angular Positioning For Supplemental Essays Based On Each School's Culture And Values</li>
                  <li>Crafting Storyline, Editing, Drafting & Reviewing Supplemental Essays - Edits & Counselor Sessions</li>
                  <li>School Specific Interview Simulation - Mock Sessions</li>
                  <li>Waitlist And Deferral Support</li>
                  <li>Merit-Based Scholarships - Identification & Application Support - Edits Counselor Sessions & Number Of Scholarships</li>
                  <li>Additional Application Review By A Panel Of 3 Former Admissions Committee Members</li>
                  <li>Appeal Letter Drafting & Editing</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul className="grades">
                  <li>Decisive list of colleges</li>
                  <li>Course-specific Internships</li>
                  <li>Narrative Writing</li>
                  <li>International Tests Taking</li>
                  <li>Final Drafts and Editing College Essays</li>
                  <li>Mock Interview Preparations</li>
                  <li>Portfolio Development </li>
                  <li>Final Activities List</li>
                  <li>Submitting and Publishing Research Projects</li>
                  <li>EA/ED, Regular and Rolling Applications</li>
                  <li>Documenting Passion Projects</li>
                  <li>Community Service Hours</li>
                  <li>Duke of the Edinburgh Award</li>
                  <li>Transactional conversations with Universities</li>
                  <li>Summer School Projects</li>
                  <li>Scholarships Essays and Applications</li>
                  <li>Offer Letter Reviewing</li>
                  <li>Confirmed and Insurance College Choices</li>
                  <li>Applications for Off/On Campus Accommodation</li>
                  <li>Student Visa Interviews and Assistance</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </div>
        <Footer />
        {isLoader && <div className="loader">
          <img src={loader} />
        </div>}
      </div>
    </div>
  )
};
