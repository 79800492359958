import React from "react";
import ReactDOM from "react-dom";
import { render } from 'react-dom';
import {Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { createRoot } from 'react-dom/client';
import { Login } from "./components/login/login";
import { Home } from "./components/home/home";
import "./index.css";
import "../src/assets/style/style.scss";
import { AboutUs } from "./components/aboutus/aboutus";
import { Programs } from "./components/programs/programs";

import { CaseStudiesList } from "./components/casestudies/list";
import { CaseStudiesView } from "./components/casestudies/view";
import { Preparation } from "./components/preparation/preparation";
import { Team } from "./components/team/list";
import { Projects } from "./components/projects/projects";
import { ContactUs } from "./components/contact-us/contact-us";
import { AdminContactUs } from "./components/admin/contact-list";

export const history = createBrowserHistory();
// http://localhost:3000/6034dc5893da7531538c6b4f/5e26950a1d5f600929cafa07/60acb16a5d4f7a06e42acd26/121
class App extends React.Component {
  render() {
    return (
      <div className="app">
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/aboutus" component={AboutUs} />
            <Route exact path="/programs" component={Programs} />
            <Route exact path="/case-studies" component={CaseStudiesList} />
            <Route exact path="/case-studies/:URL" component={CaseStudiesView} />
            <Route exact path="/college-preparation" component={Preparation} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/research-project" component={Projects} />
            <Route exact path="/contact-us" component={ContactUs} />

            <Route exact path="/admin/contact" component={AdminContactUs} />
            
            {/* <Route exact path="/login" component={Login} />
            
            <Route exact path="/bookedseats" component={BookedSeats} />
            <Route exact path="/student/:id" component={StudentView} /> */}
          </Switch>
        </Router>
      </div>
    );
  }
}

// const el = document.createElement("div");
// document.body.append(el);
// ReactDOM.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>, el);


// ReactDOM.render(<App />, document.getElementById("root"));


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App/>);
