
import React, { useState, useEffect, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import loader from "../../assets/img/loader.gif";
import "react-datepicker/dist/react-datepicker.css";
import { Header } from '../common/header';
import { Footer } from '../common/footer';
export const CaseStudiesView = React.memo((props) => {
    const { pathname } = useLocation();
    const { state } = props.location;
    const histroy = useHistory();

    const [isLoader, setIsLoader] = useState(false)

    useEffect(() => {

    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const paymentPage = () => {
        histroy.push('/payments');
    }

    return (
        <div className="main_wrapper">
            <div className="main_inner">
                <Header />
                <div className="main_banner">
                    <div className="left_main case_view_left">
                        <h3>{state?.title}</h3>
                        <h3 className="admit"><span>ADMIT: </span>{state?.uni}</h3>
                        <h4>{state?.desc1}</h4>
                        <h4>{state?.desc2}</h4>

                    </div>
                    <div className="right_main case_view_right">
                        <img src={state?.img} />
                    </div>
                </div>
                <div className="vision case_view_detail">
                    <div className="common_vis">
                        <h2>Applicant Persona</h2>
                        <h4 className=""><span>School</span> <span>{state?.school}</span></h4>
                    </div>
                    <div className="common_vis">
                        <h2>Challenge</h2>
                        <h4 className="">{state?.challenge}</h4>
                    </div>
                    <div className="common_vis">
                        <h2>Ease2Uni structure provided:</h2>
                        <h4 className="">{state?.provided}</h4>
                    </div>
                    <div className="common_vis">
                        <h2>Result</h2>
                        <h4 className="">{state?.result}</h4>
                    </div>
                </div>


                <Footer />

                {isLoader && <div className="loader">
                    <img src={loader} />
                </div>}
            </div>
        </div>
    )
})
