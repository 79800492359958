const Cases = [{
  title: 'Rishabh Discovered that his Social Skills were best to use when we brainstormed his college application essay',
  uni: 'Tufts University',
  desc1: 'Rishabh had great people skills and that was the highlight he wanted to exhibit on his college application Essay.',
  desc2: 'We helped him navigate the process and receive an acceptance to Tufts University USA.',
  school: 'International Baccalaureate',
  challenge: 'Rishabh ws moving cities in India every third year because of his parent’s job, as a result he had friends in different cities but wanted to use this as a strength of his character. As a new applicant, he was worried about ensuring his application documents were complete and submitted on time. In addition, Rishabh was concerned he wouldn’t have the time to produce a stellar application around his busy schedule, filled with IB credits, school coursework, and extracurricular involvement.',
  provided: 'We built a detailed timeline plan based on Rishabh’s input and schedule to ensure all his documents would be edited, perfected, and submitted on time. We carefully helped him strike a balance between his school coursework and preparing college applications, ensuring he prioritizes well. The biggest wonderwork was advising Rishabh to come up with a social media page where he connected with peers that were also constantly moving cities and he connected with them and they shared concerns and strengths. His college application essay highlighted his social persona and his empathetic and leadership qualities.',
  result: 'Rishabh applied to universities in the USA and was overjoyed to receive an acceptance letter from Tufts University.',
  link:'student-personality-extracurriculars-usa-university-admission',
  img: 'https://easeuni-7a72f.web.app/img/tufts.png'
},
{
  title: 'Sana was able to get admission basis her strong extracurricular profile',
  uni: 'Amsterdam University',
  desc1: 'Sana was a prodigy in Music and Fine Arts, in fact, she had a very elaborate list of participation in events. However, she knew better than anyone that getting into a top college takes more than just good grades and test scores. She approached us to help her build her portfolio that had the element of storytelling and could bring to front her artistic prowess.',
  desc2: 'Sana was inspired by the Arts in Europe and she wanted to explore the region along with her  studies. But given the multiple options, she was not sure where to begin her studies at. After through discussions we narrowed down to Amsterdam and the UVA became her dream school.',
  school: 'CBSE',
  challenge: 'Portfolio structuring can be challenging to conceptualize. Students often wonder if their experiences, interests, and passions are unique enough to separate them from thousands of other applicants. Sana knew that she has a lot of diverse work in her kitty but for a competitive spot at the university, she needs her best work with a thematic representation.',
  provided: 'First, we spoke with Sana and actively listened to her concerns and asked her to list all her portfolio inclusions. We assured her that a perceived lack of portfolio differentiation is a common concern among high school students. We began with a deep dive into Sana’s life, work, and school experiences. Through an admissions committee-style perspective, we identified activities and experiences that could strategically position her as a standout, competitive applicant.',
  result: 'Sana was accepted at the University of Amsterdam and received a scholarship too.',
  link:'study-abroad-activities-europe-university-admission',
  img: 'https://easeuni-7a72f.web.app/img/amsterdam.png'
},
{
  title: 'Aakash discovered ways of listing his extracurriculars list into a perfect application',
  uni: 'King’s College London',
  desc1: 'Aakash came to us because he felt he didn’t have any extracurricular activities of note to add to his college applications. He was denoting more time to his academics throughout high school and he had little time to devote to school clubs and activities.',
  desc2: 'Sometimes, having a clear perspective on your college candidacy is hard. Aakash came to us for help, and we helped him list the meaningful activities he’s participated in. Identifying his participations and advising him further to build up on them made him confident to apply to the top universities in the UK.',
  school: 'Cambridge International',
  challenge: 'Aakash was worried about not having enough extracurricular activities to get accepted to his dream colleges. He stated that his  grades won’t be enough, especially when comparing his extracurriculars list with other students. Ease2Uni was set to help Aakash with his concerns.',
  provided: 'We ensured that the extracurriculars do not have to be as the same as that of his classmates and any participation he has on his list can further be built on for his college essay. When college hopefuls think of extracurriculars, they often think of sports, clubs, or volunteer work; however, there are many other activities that can level up their applications. We could identify three of his activities and advise him on a further participation list and element his abilities like teamwork, creativity and entrepreneurial skills to his list of extracurriculars. Aaksah also volunteered at home for various chores and that too was strategically placed in his essay.',
  result: 'Aakash goat an acceptance from 5 colleges in the UK, having Russell Group institutions, he made a choice of accepting his King’s College offer.',
  link:'study-in-uk-counsellor-personal-statement',
  img: 'https://easeuni-7a72f.web.app/img/king.svg'
},
{
  title: 'Karishma was concerned about her below average score and yet seeked to study at a good-fit university in the USA for Computer Science',
  uni: 'Duke University',
  desc1: 'Karishma was highly motivated to study in a top university in the USA and had great skills and confidence which would promise her  great career ahead. Yet, due to unforeseen circumstances her score in Grade 11 was low, due to which she was anxious that her dream could not be a reality and she would have to settle for whatever comes her way.',
  desc2: '',
  school: 'Cambridge International / IB',
  challenge: 'Many motivated, diligent, and curious students may earn a lower grade than expected, especially factoring in reasons beyond their control. Karishma came from a big school with a large number of students, where she could not receive the one-on-one, in-depth application support she needed.',
  provided: 'We built a detailed timeline plan based on Rishabh’s input and schedule to ensure all his documents would be edited, perfected, and submitted on time. We carefully helped him strike a balance between his school coursework and preparing college applications, ensuring he prioritizes well. The biggest wonderwork was advising Rishabh to come up with a social media page where he connected with peers that were also constantly moving cities and he connected with them and they shared concerns and strengths. His college application essay highlighted his social persona and his empathetic and leadership qualities.',
  result: 'Karishma received an offer from Duke University to study Computer Science.',
  link:'common-app-counsellor-average-marks-education-abroad',
  img: 'https://easeuni-7a72f.web.app/img/duke.png'
}
,{
  title: 'Anya was overwhelmed with the list of colleges for her studies abroad; we helped her narrow down her choices patiently.',
  uni: 'University of British Columbia',
  desc1: 'Anya knew that the hustling schedule she has with her school coursework, her extra classes, AP tests and other examinations, she would have no time to research and enlist the best-fit universities for herself. Rather, she said “I will do what I can best, get the grades, and you do the rest for me.”....How could we not do OUR work for her then?',
  desc2: 'Anya opted to make applications to three countries, vis-a-vis Canada, USA and UK. She wanted to apply to the TOP ranked institutions in all the countries to diversify her choices and maximize her options.',
  school: 'CBSE',
  challenge: 'Many students struggle to build their school list. Many parents may have limited knowledge of the college process in general, especially when school counselors oversee hundreds of students each year. Therefore, students may not have the resources or insider knowledge to build a varied, tailored list. At Ease2Uni we precisely do the work for the students where they are able to focus on their school deadlines and examination preparation. College applications and decision making is a symbiotic process where each student must be ensured a guided and handheld approach addressing their specific concerns and requirements.',
  provided: 'With Anya because of her limited time we expedited the process and met her online for counselling sessions during the days she had time on her watch. We also got as much information on her scores, extracurriculars, budget, and other preferences by recording her responses on a call. In just two meetings we presented her with an exhaustive list of universities, and with her inputs we started the applications. Anya was involved in the process with her essays and Letters of Recommendations, however, she opted for our reviewing services as well.',
  result: 'Anya received offers from the universities in all the three countries we made applications to, and she finally went ahead with the University of British Columbia offer with a decent scholarship.',
  link: 'study-in-canada-counsellor-list-of-universities-extracurriculars',
  img: 'https://easeuni-7a72f.web.app/img/columbia.png'
}
]

export default Cases;