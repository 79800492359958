
import React, { useState, useEffect, useReducer } from "react";
import { useHistory, NavLink } from "react-router-dom";
import Logo from "../../assets/img/logo.png";
import MENU from "../../assets/img/list.png";
import Cross from "../../assets/img/cross.png";

export const Header = () => {
    const histroy = useHistory();
    const [menuClass, setMenuClass] = useState(false);

    useEffect(() => {

    }, [])

    const handleMenu =()=>{
        setMenuClass(!menuClass)
    }
    const handleNav=()=>{
        setMenuClass(false)
    }

    // const paymentPage = () => {
    //     histroy.push('/payments');
    // }
    // const seatsPage = () => {
    //     histroy.push('/bookedseats');
    // }

    return (
        <div className="header_main">
            <span className="logo"><NavLink to='/'><img src={Logo} alt="Logo" /></NavLink></span>
            <div className={menuClass ? 'navigation act': 'navigation'}>
                <img className="menu_mob" onClick={()=> handleMenu()} src={MENU} alt="menu"/>
                <img src={Cross} className="close_menu" onClick={()=> handleMenu()} alt="cross" />
                <ul className={menuClass ? 'open': ''}>
                    <li onClick={()=> handleNav()}><NavLink to='/aboutus'>Why Ease2Uni</NavLink></li>
                    <li onClick={()=> handleNav()}><NavLink to='/programs'>Programs</NavLink></li>
                    <li onClick={()=> handleNav()}><NavLink to='/case-studies'>Case Studies</NavLink></li>
                    <li onClick={()=> handleNav()}><NavLink to='/college-preparation'>College Preparation</NavLink></li>
                    <li onClick={()=> handleNav()}><NavLink to='/research-project'>Research Project</NavLink></li>
                    <li onClick={()=> handleNav()}><NavLink to='/team'>Our Team</NavLink></li>
                    <li onClick={()=> handleNav()}><a href="https://calendly.com/ease2uni/30min" target="_blank" className="c2a">Free Consultation</a></li>
                </ul>
            </div>
        </div>
    )
};
